import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Flex, Box, Button, Heading, Text } from 'theme-ui'

const styles = {
  wrapper: src => ({
    alignItems: `center`,
    flexDirection: [`column`, `row`],
    bg: `omegaLighter`,
    backgroundImage: src && [`none`, `none`, `url(${src})`],
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `70% bottom`,
    borderRadius: `default`,
    width: `full`,
    p: 4
  }),
  left: {
    flexBasis: `2/3`
  },
  right: {
    flexBasis: `1/3`,
    textAlign: `right`
  },
  heading: {
    color: `omegaDark`,
    //fontWeight: `normal`
  },
  subheading: {
    color: `omegaDark`,
    mb: [3, 0]
  }
}

const BannerHorizontalDeletionLinks = () => {
  return (
    <Flex sx={styles.wrapper}>
      <Box sx={styles.left}>
        <Heading variant='h2' sx={styles.heading}>
          Delete accounts using direct deletion links
        </Heading>
        <Text sx={styles.subheading}>Don't have time for reading? Click the link and delete immediately.</Text>
        <Text sx={styles.subheading}>Deletion links redirect you directly to deletion page of chosen application.</Text>
      </Box>
      <Box sx={styles.right}>
        <Button as={Link} to='/deletion-links' variant='primary' aria-label='Download Report'>
          Delete my account
        </Button>
      </Box>
    </Flex>
  )
}

export default BannerHorizontalDeletionLinks
